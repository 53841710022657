import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/8B/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/8B/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/8B/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/8B/4.jpg';
import p5 from 'assests/Photos/ClassAssembly/2023/8B/5.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';
import Ph4 from 'views/Shikar2022/Ph4';

const ClassAssembly8B2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 2,
      cols: 2,
    },
    {
      src: p2,
      source:p2,
      rows: 1.5,
      cols: 1,
    },
    {
          src: p3,
          source: p3,
          rows: 1.5,
          cols: 1,
        },
        {
          src: p4,
          source: p4,
          rows: 1.5,
          cols: 1,
        },
        
        {
          src: p5,
          source:p5,
          rows: 1.5,
          cols: 1,
        },
  
    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Engineer’s Day 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 8B  Date:15 SEPTEMBER 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of class 8B presented an inspiring, informative and humorous assembly on the occasion of Engineer’s Day (15.09.23). The students depicted the importance of engineers in the society. They performed on a humorous musical beats showing the role of engineers in our everyday life with expressive dances. Their music depicted a world without engineers compared to a world with engineers and the difference it would make without them.
 <br></br>
 The assembly highlighted the achievements of engineers and their contribution to the society. Engineer’s Day is a day to celebrate the ingenuity and innovation of engineers. It is a day to thank the engineers and inspire the next generation of nation builders. The assembly was well received and appreciated by both the teachers and students.
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “SCIENTISTS STUDY THE WORLD AS IT IS, ENGINEERS CREATE THE WORLD THAT HAS NEVER BEEN”

<br/>
        </Typography>
     

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly8B2023;